// source: qis/order/v1/order.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var qis_quote_v1_quote_pb = require('../../../qis/quote/v1/quote_pb.js');
goog.object.extend(proto, qis_quote_v1_quote_pb);
var qis_product_v1_product_pb = require('../../../qis/product/v1/product_pb.js');
goog.object.extend(proto, qis_product_v1_product_pb);
var qis_parties_v1_party_pb = require('../../../qis/parties/v1/party_pb.js');
goog.object.extend(proto, qis_parties_v1_party_pb);
var qis_transaction_v1_transaction_pb = require('../../../qis/transaction/v1/transaction_pb.js');
goog.object.extend(proto, qis_transaction_v1_transaction_pb);
goog.exportSymbol('proto.qis.order.v1.Order', null, global);
goog.exportSymbol('proto.qis.order.v1.Status', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.order.v1.Order = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.order.v1.Order, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.order.v1.Order.displayName = 'proto.qis.order.v1.Order';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.order.v1.Order.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.order.v1.Order.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.order.v1.Order} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.order.v1.Order.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    party: (f = msg.getParty()) && qis_parties_v1_party_pb.Party.toObject(includeInstance, f),
    counterPartyTransaction: (f = msg.getCounterPartyTransaction()) && qis_transaction_v1_transaction_pb.CounterpartyTransaction.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 4, 0),
    product: (f = msg.getProduct()) && qis_product_v1_product_pb.Product.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && qis_quote_v1_quote_pb.Quote.toObject(includeInstance, f),
    quantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    reason: jspb.Message.getFieldWithDefault(msg, 8, ""),
    externalTransactionId: jspb.Message.getFieldWithDefault(msg, 9, ""),
    withdrawRequestId: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.order.v1.Order}
 */
proto.qis.order.v1.Order.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.order.v1.Order;
  return proto.qis.order.v1.Order.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.order.v1.Order} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.order.v1.Order}
 */
proto.qis.order.v1.Order.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new qis_parties_v1_party_pb.Party;
      reader.readMessage(value,qis_parties_v1_party_pb.Party.deserializeBinaryFromReader);
      msg.setParty(value);
      break;
    case 3:
      var value = new qis_transaction_v1_transaction_pb.CounterpartyTransaction;
      reader.readMessage(value,qis_transaction_v1_transaction_pb.CounterpartyTransaction.deserializeBinaryFromReader);
      msg.setCounterPartyTransaction(value);
      break;
    case 4:
      var value = /** @type {!proto.qis.order.v1.Status} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 5:
      var value = new qis_product_v1_product_pb.Product;
      reader.readMessage(value,qis_product_v1_product_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 6:
      var value = new qis_quote_v1_quote_pb.Quote;
      reader.readMessage(value,qis_quote_v1_quote_pb.Quote.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuantity(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setReason(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalTransactionId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setWithdrawRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.order.v1.Order.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.order.v1.Order.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.order.v1.Order} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.order.v1.Order.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParty();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      qis_parties_v1_party_pb.Party.serializeBinaryToWriter
    );
  }
  f = message.getCounterPartyTransaction();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      qis_transaction_v1_transaction_pb.CounterpartyTransaction.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      qis_product_v1_product_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      qis_quote_v1_quote_pb.Quote.serializeBinaryToWriter
    );
  }
  f = message.getQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getReason();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExternalTransactionId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getWithdrawRequestId();
  if (f !== 0) {
    writer.writeUint64(
      10,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.order.v1.Order.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional qis.parties.v1.Party party = 2;
 * @return {?proto.qis.parties.v1.Party}
 */
proto.qis.order.v1.Order.prototype.getParty = function() {
  return /** @type{?proto.qis.parties.v1.Party} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_party_pb.Party, 2));
};


/**
 * @param {?proto.qis.parties.v1.Party|undefined} value
 * @return {!proto.qis.order.v1.Order} returns this
*/
proto.qis.order.v1.Order.prototype.setParty = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.clearParty = function() {
  return this.setParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.order.v1.Order.prototype.hasParty = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional qis.transaction.v1.CounterpartyTransaction counter_party_transaction = 3;
 * @return {?proto.qis.transaction.v1.CounterpartyTransaction}
 */
proto.qis.order.v1.Order.prototype.getCounterPartyTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.CounterpartyTransaction} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_transaction_pb.CounterpartyTransaction, 3));
};


/**
 * @param {?proto.qis.transaction.v1.CounterpartyTransaction|undefined} value
 * @return {!proto.qis.order.v1.Order} returns this
*/
proto.qis.order.v1.Order.prototype.setCounterPartyTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.clearCounterPartyTransaction = function() {
  return this.setCounterPartyTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.order.v1.Order.prototype.hasCounterPartyTransaction = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Status status = 4;
 * @return {!proto.qis.order.v1.Status}
 */
proto.qis.order.v1.Order.prototype.getStatus = function() {
  return /** @type {!proto.qis.order.v1.Status} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.qis.order.v1.Status} value
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional qis.product.v1.Product product = 5;
 * @return {?proto.qis.product.v1.Product}
 */
proto.qis.order.v1.Order.prototype.getProduct = function() {
  return /** @type{?proto.qis.product.v1.Product} */ (
    jspb.Message.getWrapperField(this, qis_product_v1_product_pb.Product, 5));
};


/**
 * @param {?proto.qis.product.v1.Product|undefined} value
 * @return {!proto.qis.order.v1.Order} returns this
*/
proto.qis.order.v1.Order.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.order.v1.Order.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional qis.quote.v1.Quote quote = 6;
 * @return {?proto.qis.quote.v1.Quote}
 */
proto.qis.order.v1.Order.prototype.getQuote = function() {
  return /** @type{?proto.qis.quote.v1.Quote} */ (
    jspb.Message.getWrapperField(this, qis_quote_v1_quote_pb.Quote, 6));
};


/**
 * @param {?proto.qis.quote.v1.Quote|undefined} value
 * @return {!proto.qis.order.v1.Order} returns this
*/
proto.qis.order.v1.Order.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.order.v1.Order.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double quantity = 7;
 * @return {number}
 */
proto.qis.order.v1.Order.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional string reason = 8;
 * @return {string}
 */
proto.qis.order.v1.Order.prototype.getReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.setReason = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string external_transaction_id = 9;
 * @return {string}
 */
proto.qis.order.v1.Order.prototype.getExternalTransactionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.setExternalTransactionId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional uint64 withdraw_request_id = 10;
 * @return {number}
 */
proto.qis.order.v1.Order.prototype.getWithdrawRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.qis.order.v1.Order} returns this
 */
proto.qis.order.v1.Order.prototype.setWithdrawRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * @enum {number}
 */
proto.qis.order.v1.Status = {
  UNSPECIFIED: 0,
  FULFILLED: 1,
  ACCEPTED: 2,
  REJECTED: 3,
  SETTLEMENT: 4
};

goog.object.extend(exports, proto.qis.order.v1);
