import { Instrument } from '../proto/qis/instrument/v1/instrument_pb';
import { Valuation, ValuationType } from '../proto/qis/pricing/v1/pricing_pb';
import { MoneyCode } from '../proto/qis/regional/v1/regional_pb';
import { InstrumentRequest } from '../proto/qis/marketdata/v1/marketdata_api_pb';
import { MarketDataServicePromiseClient } from '../proto/qis/marketdata/v1/marketdata_api_grpc_web_pb';
import { AuthorizationInterceptor } from './interceptors/authorization_interceptor';

export default class MarketdataService {
    constructor (private marketDataService: MarketDataServicePromiseClient = new MarketDataServicePromiseClient(process.env.VUE_APP_STREAM_URL, null, {'unaryInterceptors': [new AuthorizationInterceptor()]})) {
    }

    WatchRealtime(data?:any) {
        let request = new InstrumentRequest();
        let instrument = new Instrument();
        instrument.setSymbol("BTCUSD");
        request.addInstrument(instrument);
        instrument = new Instrument();
        instrument.setSymbol("ETHUSD");
        request.addInstrument(instrument);
        try {
            return this.marketDataService.watchRealtime(request, {});
        } catch (err) {
            throw err
        }
    }

    Snapshot(data?:any) {
        let request = new InstrumentRequest();
        let instrument = new Instrument();
        let valuation = new Valuation();
        valuation.setMoney(MoneyCode.USD);
        valuation.setType(ValuationType.CLOSE_PRICE_ADJUSTED);

        instrument.getValuationList().push(valuation);
        instrument.setSymbol('BTCUSD');
        request.addInstrument(instrument);

        instrument = new Instrument();
        instrument.setSymbol('ETHUSD');
        instrument.getValuationList().push(valuation);
        request.addInstrument(instrument);

        try {
            return this.marketDataService.snapshot(request, {});
        } catch (err) {
            throw err
        }
    }
}