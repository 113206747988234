
import EventBus from "../event-bus";
import MarketdataService from '../services/marketdata';
import NotificationService from '../services/notification';
import { NotificationEvent } from '@/model/NotificationModel';
import { Builder } from "builder-pattern";

export default {
  name: "notification-listener",
  data() {
    return {
      streamMarketData: {} as any,
      marketdataService: {} as MarketdataService,
      notificationService: {} as NotificationService,
      streamData: {} as any,
      connected: false as boolean,
      streamCheck: {} as Number,
      lastToastTime: 0 as number,
      userId: {} as String,
    };
  },
  mounted() {
    let timeout = 10000;
    let retryReconnect = timeout / 2;
    this.streamCheck = setInterval(() => {
      this.retryConnection(retryReconnect);
		}, retryReconnect);
    EventBus.$on('streamCheck', event => {
      let now = Date.now();
      if (!event) {
        this.connected = event;
        if ((this.lastToastTime + timeout - 1000) < now) {
          this.lastToastTime = now;
          this.$toast.error("Connection lost, retrying to connect in 10s.", {
              position: "bottom-right",
              timeout: timeout,
              icon: true,
              rtl: false
          });
        }
      } else {
        this.connected = true;
        this.$root.bus.$emit('streamRecovery', true);
      }
    });
    this.retryConnection(retryReconnect);
  },
  beforeDestroy() {
    clearInterval(this.streamCheck);
  },
  created() {
    this.marketdataService = new MarketdataService();
    this.notificationService = new NotificationService();
    this.userId = String(Date.now());
    EventBus.$on('SubscribeMarketData', (res) => {
      try {
        if (res) {
          this.subscribeMarketData();
        }
      } catch (e) {
          //console.log(e);
      }
    });
    EventBus.$on('CancelMarketData', (res) => {
      try {
        if (res && this.streamMarketData != undefined) {
          this.streamMarketData.cancel();
        }
      } catch (e) {
          //console.log(e);
      }
    });
  },
  methods: {
    retryConnection(timeout: number) {
      if (!this.connected) {
        this.subscribeNotification();
        //this.subscribeMarketData();
        this.activeConnection(timeout);
      }
    },
    activeConnection(timeout?: number) {
      let now = Date.now();
      if (this.lastToastTime == 0) {
        EventBus.$emit('streamCheck', true);
      }
      if (timeout != undefined && !this.connected && (this.lastToastTime + timeout*2 + 500) < now) {
        EventBus.$emit('streamCheck', true);
      }
    },
    subscribeNotification() {
      var toast = this.$toast;
      var streamNotification = this.notificationService.Subscribe(this.userId);
      // Stream Notification
      streamNotification.on('data', function(response: any) {
          try {
              let res: any = response.toObject();
              if (res.notification) {
                let event:any = res.notification;
                if (event.rfqCreatedEvent) {
                  let notification = Builder<any>()
                                  .notification(event.rfqCreatedEvent)
                                  .build()
                  EventBus.$emit('rfqCreatedEvent', notification);
                }
                if (event.rfqUpdatedEvent) {
                  let notification = Builder<any>()
                                  .notification(event.rfqUpdatedEvent)
                                  .build()
                  EventBus.$emit('rfqUpdatedEvent', notification);
                }
              }
          } catch (except) {
            toast.error("Error sending event", {
                position: "bottom-right",
                timeout: false,
                icon: true,
                rtl: false
            });
          }
      });
      streamNotification.on('status', function(status) {
      });
      streamNotification.on('error', function(e) {
        toast.error(`subscribeNotification ${e.message}`, {
              position: "bottom-right",
              timeout: 3000,
              icon: true,
              rtl: false
          });
        EventBus.$emit('streamCheck', false);
      });
      streamNotification.on('end', function() {
        EventBus.$emit('streamCheck', false);
      });
    },
    subscribeMarketData() {
      this.connected = true;
      var toast = this.$toast;
      this.streamMarketData = this.marketdataService.WatchRealtime();
      // Stream Market Data
      this.streamMarketData.on('data', function(response: any) {
          try {
              let res: any = response.toObject();
              if (res.instrument) {
                let notification = Builder<NotificationEvent>()
                                .marketDataEvent(res)
                                .build()
                EventBus.$emit('marketDataEvent', notification);
              }
          } catch (except) {
            toast.error("Error sending event", {
                position: "bottom-right",
                timeout: false,
                icon: true,
                rtl: false
            });
          }
      });
      this.streamMarketData.on('status', function(status) {
      });
      this.streamMarketData.on('error', function(e) {
        toast.error(`subscribeMarketData ${e.message}`, {
              position: "bottom-right",
              timeout: 3000,
              icon: true,
              rtl: false
          });
        EventBus.$emit('streamCheck', false);
      });
      this.streamMarketData.on('end', function() {
        EventBus.$emit('streamCheck', false);
      });
    },
  }
};
