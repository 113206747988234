// source: qis/tax/v1/tax.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.qis.tax.v1.TaxReceipt', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.tax.v1.TaxReceipt = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.tax.v1.TaxReceipt, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.tax.v1.TaxReceipt.displayName = 'proto.qis.tax.v1.TaxReceipt';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.tax.v1.TaxReceipt.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.tax.v1.TaxReceipt.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.tax.v1.TaxReceipt} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.tax.v1.TaxReceipt.toObject = function(includeInstance, msg) {
  var f, obj = {
    receiptNumber: jspb.Message.getFieldWithDefault(msg, 1, 0),
    amount: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    verificationCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    rpsNumber: jspb.Message.getFieldWithDefault(msg, 5, 0),
    clientTaxNumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    email: jspb.Message.getFieldWithDefault(msg, 7, ""),
    canceled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    issueTime: (f = msg.getIssueTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    clientName: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.tax.v1.TaxReceipt}
 */
proto.qis.tax.v1.TaxReceipt.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.tax.v1.TaxReceipt;
  return proto.qis.tax.v1.TaxReceipt.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.tax.v1.TaxReceipt} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.tax.v1.TaxReceipt}
 */
proto.qis.tax.v1.TaxReceipt.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setReceiptNumber(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAmount(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVerificationCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRpsNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientTaxNumber(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanceled(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setIssueTime(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.tax.v1.TaxReceipt.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.tax.v1.TaxReceipt.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.tax.v1.TaxReceipt} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.tax.v1.TaxReceipt.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceiptNumber();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getVerificationCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRpsNumber();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getClientTaxNumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCanceled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIssueTime();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getClientName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint64 receipt_number = 1;
 * @return {number}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getReceiptNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setReceiptNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double amount = 2;
 * @return {number}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional string verification_code = 3;
 * @return {string}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getVerificationCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setVerificationCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional uint64 rps_number = 5;
 * @return {number}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getRpsNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setRpsNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string client_tax_number = 6;
 * @return {string}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getClientTaxNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setClientTaxNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string email = 7;
 * @return {string}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool canceled = 8;
 * @return {boolean}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getCanceled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setCanceled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp issue_time = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getIssueTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
*/
proto.qis.tax.v1.TaxReceipt.prototype.setIssueTime = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.clearIssueTime = function() {
  return this.setIssueTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.tax.v1.TaxReceipt.prototype.hasIssueTime = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string client_name = 10;
 * @return {string}
 */
proto.qis.tax.v1.TaxReceipt.prototype.getClientName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.tax.v1.TaxReceipt} returns this
 */
proto.qis.tax.v1.TaxReceipt.prototype.setClientName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


goog.object.extend(exports, proto.qis.tax.v1);
