export function randomString(length = 7) {
  const possibleChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let text = "";

  for (let i = 0; i < length; i++)
    text += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));

  return text;
}

export function getToday() {
  var dateobj = new Date();
  dateobj.setHours(0);
  dateobj.setMinutes(0);
  dateobj.setSeconds(0);
  dateobj.setMilliseconds(0);
  return dateobj;
}

export function getTomorrow() {
  var dateobj = new Date();
  dateobj.setTime(dateobj.getTime() + (1 * 24 * 60 * 60 * 1000));
  dateobj.setHours(0);
  dateobj.setMinutes(0);
  dateobj.setSeconds(0);
  dateobj.setMilliseconds(0);
  return dateobj;
}