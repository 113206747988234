// source: qis/transaction/v1/pix.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.qis.transaction.v1.Bearer', null, global);
goog.exportSymbol('proto.qis.transaction.v1.PIX', null, global);
goog.exportSymbol('proto.qis.transaction.v1.PIXImage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.PIX = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.PIX, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.PIX.displayName = 'proto.qis.transaction.v1.PIX';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.PIXImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.PIXImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.PIXImage.displayName = 'proto.qis.transaction.v1.PIXImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.transaction.v1.Bearer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.transaction.v1.Bearer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.transaction.v1.Bearer.displayName = 'proto.qis.transaction.v1.Bearer';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.PIX.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.PIX.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.PIX} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.PIX.toObject = function(includeInstance, msg) {
  var f, obj = {
    taxId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    totalAmount: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    idEndToEnd: jspb.Message.getFieldWithDefault(msg, 5, ""),
    destinationKey: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    paymentTime: (f = msg.getPaymentTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.PIX}
 */
proto.qis.transaction.v1.PIX.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.PIX;
  return proto.qis.transaction.v1.PIX.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.PIX} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.PIX}
 */
proto.qis.transaction.v1.PIX.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdEndToEnd(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDestinationKey(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setPaymentTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.PIX.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.PIX.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.PIX} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.PIX.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTaxId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTotalAmount();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getIdEndToEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDestinationKey();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPaymentTime();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tax_id = 1;
 * @return {string}
 */
proto.qis.transaction.v1.PIX.prototype.getTaxId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.setTaxId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.qis.transaction.v1.PIX.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.qis.transaction.v1.PIX.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional double total_amount = 4;
 * @return {number}
 */
proto.qis.transaction.v1.PIX.prototype.getTotalAmount = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.setTotalAmount = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional string id_end_to_end = 5;
 * @return {string}
 */
proto.qis.transaction.v1.PIX.prototype.getIdEndToEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.setIdEndToEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string destination_key = 6;
 * @return {string}
 */
proto.qis.transaction.v1.PIX.prototype.getDestinationKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.setDestinationKey = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.transaction.v1.PIX.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
*/
proto.qis.transaction.v1.PIX.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.PIX.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Timestamp payment_time = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.transaction.v1.PIX.prototype.getPaymentTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.transaction.v1.PIX} returns this
*/
proto.qis.transaction.v1.PIX.prototype.setPaymentTime = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.transaction.v1.PIX} returns this
 */
proto.qis.transaction.v1.PIX.prototype.clearPaymentTime = function() {
  return this.setPaymentTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.transaction.v1.PIX.prototype.hasPaymentTime = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.PIXImage.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.PIXImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.PIXImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.PIXImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    imageText: jspb.Message.getFieldWithDefault(msg, 7, ""),
    imageBase64: jspb.Message.getFieldWithDefault(msg, 8, ""),
    urlLocation: jspb.Message.getFieldWithDefault(msg, 9, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.PIXImage}
 */
proto.qis.transaction.v1.PIXImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.PIXImage;
  return proto.qis.transaction.v1.PIXImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.PIXImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.PIXImage}
 */
proto.qis.transaction.v1.PIXImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageText(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageBase64(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlLocation(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.PIXImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.PIXImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.PIXImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.PIXImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getImageText();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getImageBase64();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getUrlLocation();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string image_text = 7;
 * @return {string}
 */
proto.qis.transaction.v1.PIXImage.prototype.getImageText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIXImage} returns this
 */
proto.qis.transaction.v1.PIXImage.prototype.setImageText = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string image_base64 = 8;
 * @return {string}
 */
proto.qis.transaction.v1.PIXImage.prototype.getImageBase64 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIXImage} returns this
 */
proto.qis.transaction.v1.PIXImage.prototype.setImageBase64 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string url_location = 9;
 * @return {string}
 */
proto.qis.transaction.v1.PIXImage.prototype.getUrlLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIXImage} returns this
 */
proto.qis.transaction.v1.PIXImage.prototype.setUrlLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string external_id = 10;
 * @return {string}
 */
proto.qis.transaction.v1.PIXImage.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.transaction.v1.PIXImage} returns this
 */
proto.qis.transaction.v1.PIXImage.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.transaction.v1.Bearer.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.transaction.v1.Bearer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.transaction.v1.Bearer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.Bearer.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.transaction.v1.Bearer}
 */
proto.qis.transaction.v1.Bearer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.transaction.v1.Bearer;
  return proto.qis.transaction.v1.Bearer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.transaction.v1.Bearer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.transaction.v1.Bearer}
 */
proto.qis.transaction.v1.Bearer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.transaction.v1.Bearer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.transaction.v1.Bearer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.transaction.v1.Bearer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.transaction.v1.Bearer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.qis.transaction.v1);
