import { SubscribeNotificationRequest, SubscribeNotificationResponse } from '../proto/qis/notification/v1/notification_api_pb';
import { NotificationAPIPromiseClient } from '../proto/qis/notification/v1/notification_api_grpc_web_pb';
import { AuthorizationInterceptor } from './interceptors/authorization_interceptor';

export default class NotificationService {
    constructor (private notificationService: NotificationAPIPromiseClient = new NotificationAPIPromiseClient(process.env.VUE_APP_STREAM_URL, null, {'unaryInterceptors': [new AuthorizationInterceptor()]})) {
    }

    Subscribe(id: string) {
        let request = new SubscribeNotificationRequest();
        request.setId(String(id));
        try {
            return this.notificationService.subscribe(request, {});
        } catch (err) {
            throw err
        }
    }
}