// source: qis/rfq/v1/rfq.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var qis_quote_v1_quote_pb = require('../../../qis/quote/v1/quote_pb.js');
goog.object.extend(proto, qis_quote_v1_quote_pb);
var qis_product_v1_product_pb = require('../../../qis/product/v1/product_pb.js');
goog.object.extend(proto, qis_product_v1_product_pb);
var qis_order_v1_order_pb = require('../../../qis/order/v1/order_pb.js');
goog.object.extend(proto, qis_order_v1_order_pb);
var qis_parties_v1_party_pb = require('../../../qis/parties/v1/party_pb.js');
goog.object.extend(proto, qis_parties_v1_party_pb);
var qis_parties_v1_counterparty_pb = require('../../../qis/parties/v1/counterparty_pb.js');
goog.object.extend(proto, qis_parties_v1_counterparty_pb);
var qis_transaction_v1_transaction_pb = require('../../../qis/transaction/v1/transaction_pb.js');
goog.object.extend(proto, qis_transaction_v1_transaction_pb);
var qis_tax_v1_tax_pb = require('../../../qis/tax/v1/tax_pb.js');
goog.object.extend(proto, qis_tax_v1_tax_pb);
var google_rpc_status_pb = require('../../../google/rpc/status_pb.js');
goog.object.extend(proto, google_rpc_status_pb);
goog.exportSymbol('proto.qis.rfq.v1.RFQ', null, global);
goog.exportSymbol('proto.qis.rfq.v1.RFQStatus', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qis.rfq.v1.RFQ = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qis.rfq.v1.RFQ, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qis.rfq.v1.RFQ.displayName = 'proto.qis.rfq.v1.RFQ';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qis.rfq.v1.RFQ.prototype.toObject = function(opt_includeInstance) {
  return proto.qis.rfq.v1.RFQ.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qis.rfq.v1.RFQ} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.RFQ.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    createTime: (f = msg.getCreateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    replyTime: (f = msg.getReplyTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    expiryTime: (f = msg.getExpiryTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastUpdateTime: (f = msg.getLastUpdateTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    neededQuantity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    isIndicative: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    product: (f = msg.getProduct()) && qis_product_v1_product_pb.Product.toObject(includeInstance, f),
    quote: (f = msg.getQuote()) && qis_quote_v1_quote_pb.Quote.toObject(includeInstance, f),
    order: (f = msg.getOrder()) && qis_order_v1_order_pb.Order.toObject(includeInstance, f),
    party: (f = msg.getParty()) && qis_parties_v1_party_pb.Party.toObject(includeInstance, f),
    counterparty: (f = msg.getCounterparty()) && qis_parties_v1_counterparty_pb.Counterparty.toObject(includeInstance, f),
    transaction: (f = msg.getTransaction()) && qis_transaction_v1_transaction_pb.Transaction.toObject(includeInstance, f),
    taxReceipt: (f = msg.getTaxReceipt()) && qis_tax_v1_tax_pb.TaxReceipt.toObject(includeInstance, f),
    error: (f = msg.getError()) && google_rpc_status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qis.rfq.v1.RFQ}
 */
proto.qis.rfq.v1.RFQ.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qis.rfq.v1.RFQ;
  return proto.qis.rfq.v1.RFQ.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qis.rfq.v1.RFQ} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qis.rfq.v1.RFQ}
 */
proto.qis.rfq.v1.RFQ.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.qis.rfq.v1.RFQStatus} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreateTime(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReplyTime(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpiryTime(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastUpdateTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setNeededQuantity(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsIndicative(value);
      break;
    case 9:
      var value = new qis_product_v1_product_pb.Product;
      reader.readMessage(value,qis_product_v1_product_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    case 10:
      var value = new qis_quote_v1_quote_pb.Quote;
      reader.readMessage(value,qis_quote_v1_quote_pb.Quote.deserializeBinaryFromReader);
      msg.setQuote(value);
      break;
    case 11:
      var value = new qis_order_v1_order_pb.Order;
      reader.readMessage(value,qis_order_v1_order_pb.Order.deserializeBinaryFromReader);
      msg.setOrder(value);
      break;
    case 12:
      var value = new qis_parties_v1_party_pb.Party;
      reader.readMessage(value,qis_parties_v1_party_pb.Party.deserializeBinaryFromReader);
      msg.setParty(value);
      break;
    case 13:
      var value = new qis_parties_v1_counterparty_pb.Counterparty;
      reader.readMessage(value,qis_parties_v1_counterparty_pb.Counterparty.deserializeBinaryFromReader);
      msg.setCounterparty(value);
      break;
    case 14:
      var value = new qis_transaction_v1_transaction_pb.Transaction;
      reader.readMessage(value,qis_transaction_v1_transaction_pb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    case 15:
      var value = new qis_tax_v1_tax_pb.TaxReceipt;
      reader.readMessage(value,qis_tax_v1_tax_pb.TaxReceipt.deserializeBinaryFromReader);
      msg.setTaxReceipt(value);
      break;
    case 20:
      var value = new google_rpc_status_pb.Status;
      reader.readMessage(value,google_rpc_status_pb.Status.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qis.rfq.v1.RFQ.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qis.rfq.v1.RFQ.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qis.rfq.v1.RFQ} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qis.rfq.v1.RFQ.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCreateTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReplyTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getExpiryTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastUpdateTime();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getNeededQuantity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getIsIndicative();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      qis_product_v1_product_pb.Product.serializeBinaryToWriter
    );
  }
  f = message.getQuote();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      qis_quote_v1_quote_pb.Quote.serializeBinaryToWriter
    );
  }
  f = message.getOrder();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      qis_order_v1_order_pb.Order.serializeBinaryToWriter
    );
  }
  f = message.getParty();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      qis_parties_v1_party_pb.Party.serializeBinaryToWriter
    );
  }
  f = message.getCounterparty();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      qis_parties_v1_counterparty_pb.Counterparty.serializeBinaryToWriter
    );
  }
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      qis_transaction_v1_transaction_pb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getTaxReceipt();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      qis_tax_v1_tax_pb.TaxReceipt.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_rpc_status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.qis.rfq.v1.RFQ.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional RFQStatus status = 2;
 * @return {!proto.qis.rfq.v1.RFQStatus}
 */
proto.qis.rfq.v1.RFQ.prototype.getStatus = function() {
  return /** @type {!proto.qis.rfq.v1.RFQStatus} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.qis.rfq.v1.RFQStatus} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp create_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.rfq.v1.RFQ.prototype.getCreateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setCreateTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearCreateTime = function() {
  return this.setCreateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasCreateTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp reply_time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.rfq.v1.RFQ.prototype.getReplyTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setReplyTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearReplyTime = function() {
  return this.setReplyTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasReplyTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp expiry_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.rfq.v1.RFQ.prototype.getExpiryTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setExpiryTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearExpiryTime = function() {
  return this.setExpiryTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasExpiryTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp last_update_time = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.qis.rfq.v1.RFQ.prototype.getLastUpdateTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setLastUpdateTime = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearLastUpdateTime = function() {
  return this.setLastUpdateTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasLastUpdateTime = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double needed_quantity = 7;
 * @return {number}
 */
proto.qis.rfq.v1.RFQ.prototype.getNeededQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.setNeededQuantity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool is_indicative = 8;
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.getIsIndicative = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.setIsIndicative = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional qis.product.v1.Product product = 9;
 * @return {?proto.qis.product.v1.Product}
 */
proto.qis.rfq.v1.RFQ.prototype.getProduct = function() {
  return /** @type{?proto.qis.product.v1.Product} */ (
    jspb.Message.getWrapperField(this, qis_product_v1_product_pb.Product, 9));
};


/**
 * @param {?proto.qis.product.v1.Product|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setProduct = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional qis.quote.v1.Quote quote = 10;
 * @return {?proto.qis.quote.v1.Quote}
 */
proto.qis.rfq.v1.RFQ.prototype.getQuote = function() {
  return /** @type{?proto.qis.quote.v1.Quote} */ (
    jspb.Message.getWrapperField(this, qis_quote_v1_quote_pb.Quote, 10));
};


/**
 * @param {?proto.qis.quote.v1.Quote|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setQuote = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearQuote = function() {
  return this.setQuote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasQuote = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional qis.order.v1.Order order = 11;
 * @return {?proto.qis.order.v1.Order}
 */
proto.qis.rfq.v1.RFQ.prototype.getOrder = function() {
  return /** @type{?proto.qis.order.v1.Order} */ (
    jspb.Message.getWrapperField(this, qis_order_v1_order_pb.Order, 11));
};


/**
 * @param {?proto.qis.order.v1.Order|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setOrder = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearOrder = function() {
  return this.setOrder(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional qis.parties.v1.Party party = 12;
 * @return {?proto.qis.parties.v1.Party}
 */
proto.qis.rfq.v1.RFQ.prototype.getParty = function() {
  return /** @type{?proto.qis.parties.v1.Party} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_party_pb.Party, 12));
};


/**
 * @param {?proto.qis.parties.v1.Party|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setParty = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearParty = function() {
  return this.setParty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasParty = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional qis.parties.v1.Counterparty counterparty = 13;
 * @return {?proto.qis.parties.v1.Counterparty}
 */
proto.qis.rfq.v1.RFQ.prototype.getCounterparty = function() {
  return /** @type{?proto.qis.parties.v1.Counterparty} */ (
    jspb.Message.getWrapperField(this, qis_parties_v1_counterparty_pb.Counterparty, 13));
};


/**
 * @param {?proto.qis.parties.v1.Counterparty|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setCounterparty = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearCounterparty = function() {
  return this.setCounterparty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasCounterparty = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional qis.transaction.v1.Transaction transaction = 14;
 * @return {?proto.qis.transaction.v1.Transaction}
 */
proto.qis.rfq.v1.RFQ.prototype.getTransaction = function() {
  return /** @type{?proto.qis.transaction.v1.Transaction} */ (
    jspb.Message.getWrapperField(this, qis_transaction_v1_transaction_pb.Transaction, 14));
};


/**
 * @param {?proto.qis.transaction.v1.Transaction|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional qis.tax.v1.TaxReceipt tax_receipt = 15;
 * @return {?proto.qis.tax.v1.TaxReceipt}
 */
proto.qis.rfq.v1.RFQ.prototype.getTaxReceipt = function() {
  return /** @type{?proto.qis.tax.v1.TaxReceipt} */ (
    jspb.Message.getWrapperField(this, qis_tax_v1_tax_pb.TaxReceipt, 15));
};


/**
 * @param {?proto.qis.tax.v1.TaxReceipt|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setTaxReceipt = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearTaxReceipt = function() {
  return this.setTaxReceipt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasTaxReceipt = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.rpc.Status error = 20;
 * @return {?proto.google.rpc.Status}
 */
proto.qis.rfq.v1.RFQ.prototype.getError = function() {
  return /** @type{?proto.google.rpc.Status} */ (
    jspb.Message.getWrapperField(this, google_rpc_status_pb.Status, 20));
};


/**
 * @param {?proto.google.rpc.Status|undefined} value
 * @return {!proto.qis.rfq.v1.RFQ} returns this
*/
proto.qis.rfq.v1.RFQ.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qis.rfq.v1.RFQ} returns this
 */
proto.qis.rfq.v1.RFQ.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qis.rfq.v1.RFQ.prototype.hasError = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * @enum {number}
 */
proto.qis.rfq.v1.RFQStatus = {
  RFQ_STATUS_UNSPECIFIED: 0,
  RFQ_STATUS_REQUESTED: 1,
  RFQ_STATUS_QUOTED: 2,
  RFQ_STATUS_CANCELLED: 3,
  RFQ_STATUS_REJECTED: 4,
  RFQ_STATUS_FULFILLED: 5,
  RFQ_STATUS_SETTLEMENT: 6,
  RFQ_STATUS_PENDING: 7
};

goog.object.extend(exports, proto.qis.rfq.v1);
