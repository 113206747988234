import Vue from "vue";
import VueRouter from 'vue-router';
import jwt_decode from 'jwt-decode';

Vue.use(VueRouter);

export const routes = [
  {
    path: "/",
    name: "components",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/Components.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
    }
  },
  {
    path: "/login",
    name: "login",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/Login.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
    }
  },
  {
    path: "/register",
    name: "register",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/Register.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
    }
  },
  {
    path: "/profile",
    name: "profile",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/Profile.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/terms-of-service",
    name: "terms-of-service",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/politics/TermsOfService.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
    }
  },
  {
    path: "/marketdata-monitor",
    name: "marketdata-monitor",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/marketdata/MarkedataMonitor.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
      requiresAuth: true,
      owner: true,
    }
  },
  {
    path: "/marketdata-price-history",
    name: "marketdata-price-history",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/marketdata/PriceHistory.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
      requiresAuth: true,
      owner: true,
    }
  },
  {
    path: "/rfq-monitor",
    name: "rfq-monitor",
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/rfq/RFQMonitor.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
    meta: {
      requiresAuth: true,
      owner: true,
    }
  },
  {
    path: '/404',
    components: {
      header: () => import('./layout/AppHeader.vue'),
      default: () => import('./views/errors/404.vue'),
      footer: () => import('./layout/AppFooter.vue'),
    },
  },
  { path: "/:pathMatch(.*)*", redirect: '/404' }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: "active",
  routes,
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const authenticatedUser = localStorage.getItem('user_token');
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const owner = to.matched.some(record => record.meta.owner);
  if (requiresAuth && !authenticatedUser) {
    next('login');
  }
  if (authenticatedUser) {
    try {
      let jwt = jwt_decode(authenticatedUser);
      if (Date.now() >= Date.parse(jwt.exp)) {
        localStorage.removeItem('user_token');
        this.$root.bus.$emit('update_login', true);
        next('login');
      }
    } catch {
      next('login');
    }
  };
  if (owner) {
    try {
      let jwt = jwt_decode(authenticatedUser);
      if (jwt.roles.indexOf('OWNER') == -1) {
        next('login');
      }
    } catch {
      next('login');
    }
  }
  next();
});

export default router